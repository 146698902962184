import React, { createContext, useState, useEffect } from "react";
import {
  carregaEmpresa,
  carregaAuth,
  carregaDesativadosQuery,
} from "../services/empresaAPIService";
import {
  carregaProdutos,
  carregaCategorias,
} from "../services/produtoAPIService";
import moment from "moment";
import "moment/locale/pt-br";
import axios from "axios";
import jsonwebtoken from "jsonwebtoken";
import { useQuery } from "@tanstack/react-query";
import { advancedTime, normalTime } from "./OpenUtils";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import { refreshUser } from "../services/clienteAPIService";
import SentryCli from "@sentry/cli";

export const MainContext = createContext();

const MainProvider = (props) => {
  const [user, setUser] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [order, setOrder] = useState(null);
  const [confirmedOrder, setConfirmedOrder] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [addIndex, setAddIndex] = useState(-1);
  const [editProduct, setEditProduct] = useState({});
  const [payment, setPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [slug, setSlug] = useState("");
  const [cardapio, setCardapio] = useState(false);
  const [config, setConfig] = useState();
  const [categoryList, setCategoryList] = useState();
  const [fullList, setFullList] = useState();
  const [entrega, setEntrega] = useState(0);
  const [isAtiva, setIsAtiva] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [mesa, setMesa] = useState(0);
  const [ident, setIdent] = useState("");
  const [tipo, setTipo] = useState("");
  const [localEntrega, setLocalEntrega] = useState("");
  const [slugWithSearch, setSlugWithSearch] = useState("");
  const [host, setHost] = useState(null);
  const [mesaSalva, setMesaSalva] = useState(null);
  const [openCategori, setOpenCategori] = useState({ index: 0, panel: false });
  const [desativados, setDesativados] = useState([]);
  const [opt, setOpt] = useState({ localSecurity: false });
  const [currentIp, setCurrentIp] = useState("");
  const [pause, setPausa] = useState(false);
  const [pausaEntrega, setPausaEntrega] = useState(false);
  const [msgPause, setMsgPause] = useState("");
  moment.locale("pt-br");

  const { data } = useQuery(
    ["desativados", config?.empresa?.tenentID],
    carregaDesativadosQuery,
    {
      cacheTime: 10 * 1000,
      enabled: !!config?.empresa?.tenentID,
    }
  );

  useEffect(() => {
    if (opt.pixel) {
      ReactPixel.init(opt.pixel, {}, { autoConfig: true });
      ReactPixel.pageView();
      console.log("Pixel Registrado");
    }

    if (opt.google) {
      TagManager.initialize({ gtmId: opt.google });
      console.log("Google Registrado");
    }
  }, [opt.pixel, opt.google]);

  useEffect(() => {
    if (data) {
      if (data.status === 200) {
        setDesativados(JSON.parse(data.data.value));
      }
    }
  }, [data]);

  useEffect(() => {
    if (slug && order && order.itens) {
      localStorage.setItem(`_cart_${slug}`, JSON.stringify(order));
    }

    if (!order) {
      localStorage.removeItem(`_order_${slug}`);
      localStorage.removeItem(`_cart_${slug}`);
    }
  }, [order, slug]);

  useEffect(() => {
    // Carrega Config
    process.env.NODE_ENV === "development" && console.log("Carrega Slug");
    let localConfig = JSON.parse(localStorage.getItem(`_config_${slug}`));

    if (localConfig) setConfig(localConfig);

    async function reloadUser(user) {
      const usuario = await refreshUser({
        telefone: user.telefone,
        email: user.email,
        cliente: null,
      });

      console.log(usuario.data);

      defineUser(usuario.data);
    }

    // Carrega Usuario
    if (localStorage.getItem("_user")) {
      try {
        const userStore = localStorage.getItem("_user");

        if (userStore.includes("error")) {
          setUser(null);
          localStorage.removeItem("_user");
        } else {
          let newUser = JSON.parse(localStorage.getItem("_user"));

          //reloadUser(newUser);

          setUser(newUser);
          setOrder((o) => ({ ...o, user: newUser }));
        }
      } catch (error) {
        setUser(null);
        console.log(error, "invalid json");
      }
    }

    if (slug !== "" && localStorage.getItem(`_cart_${slug}`)) {
      process.env.NODE_ENV === "development" && console.log("setando cart");
      //setCartItems(JSON.parse(localStorage.getItem(`_${slug}`))?.itens);
      setOrder(JSON.parse(localStorage.getItem(`_cart_${slug}`)));
    }

    if (slug !== "") {
      localStorage.setItem("_slugSearch", slugWithSearch);
      localStorage.setItem("_slug", slug);
    }
  }, [slug, slugWithSearch]);

  //Carrega Empresa
  useEffect(() => {
    let innerConfig;
    let _localConfig;

    async function load() {
      const resp = await carregaAuth();

      if (localStorage.getItem(`_config_${slug}`)) {
        _localConfig = JSON.parse(localStorage.getItem(`_config_${slug}`));
      }
      console.log("carregando empresa");

      axios.defaults.headers.common = {
        Authorization: `bearer ${resp?.data?.value}`,
      };

      if (slug !== "") {
        let resp = await carregaEmpresa(slug);

        if (resp.status === 200) {
          console.log("Empresa OK");

          let dados = resp.data.value[0];
          let theme = JSON.parse(resp.data.value[0].json);
          let info = JSON.parse(dados.json);

          if (info.avancado && info.avancado.ativo) {
            advancedTime(dados, theme, mesa, tipo, setIsOpen);
          } else {
            normalTime(dados, theme, mesa, tipo, setIsOpen);
          }

          const atendimento = `Loja Fechada! Atendimento de  ${theme["atendimento"]}`;

          let ob = {};

          JSON.parse(dados.categorias).map(
            (item) => (ob[item.descricao] = item.descricaoweb)
          );

          innerConfig = {
            empresa: {
              tenentID: dados.idweb,
              nome: dados.nome,
              retirar: dados.endereco,
              atendimento,
              revisao: dados.revisao,
            },
            opt: dados.opt,
            theme,
            bairros: JSON.parse(dados.bairros),
            categorias: ob,
            clienteinterno: JSON.parse(dados.clientesinternos),
            desativados: dados.desativados,
          };

          setConfig(innerConfig);
          let _unserialized = jsonwebtoken.verify(dados.opt, "css_main_style");

          setOpt(_unserialized);

          if (_unserialized.entrega) {
            if (mesa) {
              setEntrega(0);
            } else {
              setEntrega(+_unserialized.entrega);
            }
          }

          return innerConfig;
        } else {
          localStorage.removeItem(`_config_${slug}`);
          setConfig(null);
          setIsAtiva(false);
        }
      }
    }

    load().then((res) => {
      let updatedConfig;

      if (localStorage.getItem(`_confirmedOrder_${slug}`)) {
        let newOrder = JSON.parse(
          localStorage.getItem(`_confirmedOrder_${slug}`)
        );
        setConfirmedOrder(newOrder);
      }

      async function loadCategoria() {
        console.log("carregando categoria");
        let resp = await carregaCategorias(
          res.empresa.tenentID,
          mesa ? "mesa" : cardapio ? "mesa" : "web"
        );

        if (resp.status === 200) {
          updatedConfig = { ...res, listCategories: resp.data.value };
          setCategoryList(resp.data.value);
        }
      }

      async function loadProducts() {
        let resp = await carregaProdutos(res.empresa.tenentID);
        console.log("carregando produtos..");

        if (resp.status === 200) {
          localStorage.setItem(
            `_config_${slug}`,
            JSON.stringify({ ...updatedConfig, listProducts: resp.data.value })
          );
          setFullList(resp.data.value);
        }
      }

      if (res) {
        loadCategoria();
      }

      if (_localConfig) {
        if (
          res &&
          res.empresa &&
          _localConfig.empresa &&
          _localConfig.empresa.revisao === res.empresa.revisao
        ) {
          console.log("loading Storage");
          setFullList(_localConfig.listProducts);
          //setCategoryList(_localConfig.listCategories);
          return;
        }
      }

      if (res) {
        loadCategoria();
        loadProducts();
        clearOrder();
      }
    });
  }, [mesa, slug, tipo]); //adicionei mesa e tipo

  //Libera Loading
  useEffect(() => {
    if (categoryList && fullList) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [categoryList, fullList]);

  function defineUser(user) {
    localStorage.setItem("_user", JSON.stringify(user));
    setUser(user);
  }

  function setAddressIndex(index) {
    setAddIndex(index);
  }

  function addItemCart(item) {
    try {
      ReactPixel.track("AddToCart", {
        content_ids: item.id,
        content_name: item.descricao,
        content_type: "product",
        currency: "BRL",
        value: item.valortotal,
      });
    } catch (error) {}

    setCartItems((old) => (old ? [...old, item] : [item]));

    try {
      setOrder((o) => ({
        ...o,
        itens: cartItems ? [...cartItems, item] : [item],
      }));
    } catch (error) {
      config.log(error, cartItems);
    }
  }

  function updateItemCart(item, index) {
    let newCartItem = cartItems.map((o, i) => (index === i ? item : o));
    setCartItems(newCartItem);
    setOrder((o) => ({ ...o, itens: newCartItem }));
  }

  function filteredAdress() {
    let _temp = [];

    if (config.bairros && config.bairros.length === 0 && !opt.cidades) {
      console.log("não controla enderecos");
      return user?.enderecos;
    }

    config.bairros.forEach((el) => _temp.push(el.descricao.toLowerCase()));

    if (opt.cidades) {
      opt.cidades.forEach((el) => _temp.push(el.cidade.toLowerCase()));
    }

    if (_temp.length === 0) {
      return [];
    } else if (opt.cidades) {
      return user?.enderecos?.filter((ed) =>
        _temp.includes(ed.cidade.toLowerCase())
      );
    } else {
      return user?.enderecos?.filter((ed) =>
        _temp.includes(ed.bairro.toLowerCase())
      );
    }
  }

  function getAddress() {
    if (addIndex === -1) return "";
    if (addIndex === 999) return "";
    return filteredAdress()[addIndex]?.endereco;
  }

  function defineConfirmedOrder(order) {
    let newOrder = { ...order, updateTime: { PENDENTE: new Date() } };
    localStorage.setItem(`_confirmedOrder_${slug}`, JSON.stringify(newOrder));
    setConfirmedOrder(newOrder);
    clearOrder();
  }

  function removeCartItem(index) {
    let newCartItens = cartItems.filter((_, i) => index !== i);
    setCartItems(newCartItens);
    setOrder((o) => ({ ...o, itens: newCartItens }));
  }

  function getTotal() {
    return cartItems.reduce((acc, item) => acc + item.valortotal, 0);
  }

  function setAddress(index) {
    if (index === 999) {
      setEntrega(0);
    } else {
      if (opt.entrega) {
        setAddressIndex(index);
        setEntrega(opt.entrega);
        return;
      }

      let findBairro = null;

      try {
        findBairro = config.bairros.find(
          (element) =>
            element.descricao.toLowerCase() ===
            filteredAdress()[index]?.bairro.toLowerCase()
        );
      } catch (error) {}

      if (findBairro) {
        setEntrega(findBairro.valorentrega);
      }

      if (opt.cidades) {
        let findCidade = opt.cidades.find(
          (cid) =>
            cid.cidade.toLowerCase() ===
            filteredAdress()[index]?.cidade.toLowerCase()
        );

        if (findCidade) {
          setEntrega(findCidade.valor);
        }
      }
    }

    setAddressIndex(index);
  }

  function clearOrder() {
    setCartItems([]);
    setPayment(null);
    setOrder(null);
    localStorage.removeItem(`_order_${slug}`);
    localStorage.removeItem(`_cart_${slug}`);
  }

  function updateConfirmedOrder({ status, msg: mensagemCancelamento }) {
    let newOrder = {
      ...confirmedOrder,
      status,
      mensagemCancelamento,
      updateTime: { ...confirmedOrder.updateTime, [status]: new Date() },
    };
    setConfirmedOrder(newOrder);
    localStorage.setItem(`_confirmedOrder_${slug}`, JSON.stringify(newOrder));
  }

  return (
    <MainContext.Provider
      value={{
        user,
        config,
        filterList,
        order,
        cartItems,
        confirmedOrder,
        editProduct,
        payment,
        isLoading,
        categoryList,
        fullList,
        slug,
        entrega,
        isAtiva,
        isOpen,
        mesa,
        ident,
        tipo,
        localEntrega,
        slugWithSearch,
        host,
        cardapio,
        mesaSalva,
        openCategori,
        desativados,
        opt,
        currentIp,
        pause,
        msgPause,
        addIndex,
        pausaEntrega,
        ReactPixel,
        setPausaEntrega,
        setPausa,
        setMsgPause,
        setCurrentIp,
        setDesativados,
        setOpenCategori,
        setMesaSalva,
        setCardapio,
        setHost,
        setSlugWithSearch,
        setLocalEntrega,
        setTipo,
        setIdent,
        setMesa,
        clearOrder,
        setEntrega,
        getTotal,
        setPayment,
        removeCartItem,
        setAddress,
        setAddressIndex,
        getAddress,
        defineConfirmedOrder,
        setOrder,
        addItemCart,
        defineUser,
        setFilterList,
        setEditProduct,
        updateItemCart,
        setSlug,
        updateConfirmedOrder,
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default MainProvider;
